import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../../context/context";
import Api from "../../Api/Api";
import "./style.css";
import Pagination from "../Pagination/Pagination";

let PageSize = 13;

export default function Customers() {
  const { customers, setCustomer, getCustomerData } = useContext(UserContext);

  const [select, setSelect] = useState({
    salutation: "salutation",
    firstname: "firstname",
    lastname: "lastname",
    email: "email",
    mobile: "mobile",
    telephone: "telephone",
    address: "address",
    city: "city",
    country: "country",
    nationality: "nationality",
    category: "category",
    refereename: "refereename",
    refereemail: "refereemail",
    refereemobile: "refereemobile",
    note: "note",
    openingbal: "openingbal",
    paymentterms: "paymentterms",
    poster: "poster",
    tags: "tags",
  });

  // .................................................................................................
  const [currentPage, setCurrentPage] = useState(2);

  // .................................................................................................
  useEffect(() => {
    getCustomerData();
  }, [5]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return customers.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, customers]);

  const selectArray = (a) => {
    let data = customers[a];
    setSelect(data);
  };

  const updatecustomersBtn = () => {
    Api.updatecustomer(select).then((res) => {
      console.log("Data", res.data);
      getCustomerData();
    });
  };
  const deletecustomers = () => {
    Api.deletecustomer(select.id).then((res) => {
      console.log("delete", res);
      getCustomerData();
    });
  };
  // const addcustomers = () => {
  //   Api.createinvoice(invoicedata).then((res) => {
  //     console.log("Data", res.data);
  //     getCustomerData();
  //   });
  // };

  const customersList = customers.map(
    (a, _index) => (
      <div
        onClick={() => selectArray(_index)}
        key={_index}
        style={
          _index % 2 == 0
            ? {
                background: "lightblue",
                borderRadius: "8px",
              }
            : { background: "white", borderRadius: "8px" }
        }
      >
        <ul>
          <li>{_index}</li>
          <li>{a.firstname + " " + a.lastname}</li>
          <li>{a.email}</li>
          <li>{a.nationality}</li>
          <li>{a.category}</li>
          <li>
            <div className="manageui">
              <ion-icon name="create-outline"></ion-icon>
              <ion-icon name="eye-outline"></ion-icon>
              <ion-icon name="close-circle-outline"></ion-icon>
            </div>
          </li>
        </ul>
      </div>
    ),
    [5]
  );
  return (
    <div>
      <div className="dash">
        <div className="row">
          <div className="col" align="left">
            <h3>VIEW CUSTOMERS</h3>
          </div>
          <div className="col" align="right">
            <input placeholder="Search Customer" />
          </div>
        </div>
      </div>

      <div className="Custheading">
        <ul style={{ fontWeight: "bold" }}>
          <li>ID </li>
          <li>CUSTOMER NAME</li>
          <li>EMAIL</li>
          <li>NATIONALITY </li>
          <li>SALES PERSON</li>
          <li>MANAGE.</li>
        </ul>
      </div>

      <div className="Custheading CustlistTab">{customersList}</div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={customers.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <hr />
    </div>
  );
}
