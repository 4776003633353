import React, { useContext } from "react";
import "../style.css";
import { UserContext } from "../../../../context/context";

export default function Invoices() {
  const { setInvoices, invoices, getInvoice } = useContext(UserContext);
  const item = (a) => {
    for (let b = 0; b < 1; a++) {
      console.log("data", a[b].name);
    }
  };

  const pickItem = (data) => {
    return data[0].items;
  };
  const pickcus = (data) => {
    return data[0].name;
  };
  const inv = invoices.map((a, b) => (
    <ul
      style={
        b % 2 == 0
          ? {
              background: "#ecf1df",
              fontSize: "12px",
              padding: 8,
            }
          : {
              background: "white",
              fontSize: "12px",
              padding: 8,
            }
      }
    >
      <li>{a.id}</li>
      <li>{a.savetime}</li>
      <li>{a.invoicenumber}</li>
      <li>{pickItem(a.items)}</li>
      <li>{pickcus(a.customer)}</li>
      <li>{a.duedate}</li>
      <li>{a.cost}</li>
      <li>{a.cost - a.paid}</li>
      <li>
        <div align="center">
          {a.cost == a.paid ? (
            <div style={{ background: "#db3827", color: "white" }}>Unpaid</div>
          ) : a.cost == 0 ? (
            <div style={{ background: "blue", color: "white" }}>Paid</div>
          ) : (
            <div style={{ background: "#f6e572", color: "white" }}>
              Half Paid
            </div>
          )}
        </div>
      </li>
      <li align="right">ACTION</li>
    </ul>
  ));

  return (
    <div>
      <div>
        <div>
          <div className="dash">
            <div className="row">
              <div className="col" align="left">
                <h3>VIEW INVOICES</h3>
              </div>
              <div className="col" align="right">
                <input placeholder="Search Item" />
              </div>
            </div>
          </div>

          <div className="ItemInvoice">
            <ul style={{ fontWeight: "bold", padding: 8 }}>
              <li>ID </li>
              <li>DATE</li>
              <li>INVOICE NO.</li>
              <li>ITEMS</li>
              <li>SALES PERSON</li>
              <li>DUE DATE</li>
              <li>AMOUNT</li>
              <li>BALANCE</li>
              <li>STATUS</li>
              <li align="right">ACTION</li>
            </ul>
            <hr />
            <div>{inv}</div>
            <div className="itemlistTab ">
              {" "}
              <div className="ItemInvoice"> </div>
            </div>
            <hr />
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
