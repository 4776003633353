import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./Components/Sections/Account/Login";
import Forget from "./Components/Sections/Account/Forget";
import Menu from "../src/Components/Sections/Menu/Menu";
import Navigation from "./Components/Sections/Nav/Navigation";
import Additems from "./Components/Pages/Items/additems";
import Itemlist from "./Components/Pages/Items/itemlist";
import AddCustomer from "./Components/Pages/Customers/AddCustomer";
import Customers from "./Components/Pages/Customers/Customers";
import { UserProvider } from "./context/context";
import Reciepts from "./Components/Pages/Sales/Reciepts/Reciepts";
import Invoices from "./Components/Pages/Sales/Reciepts/Invoices";
import Addsale from "./Components/Pages/Sales/Addsale";
import Addvendor from "./Components/Pages/Vendors/AddVendor";
import Vendor from "./Components/Pages/Vendors/Vendor";
import Recieptview from "./Components/Pages/Reciept/Reciept";

function App() {
  return (
    <div className=" ">
      <div className="App">
        <Router>
          <UserProvider>
            {/* <Navigation /> */}
            {/* <Menu /> */}
            <Routes>
              <Route
                path="/"
                element={
                  <div className="container">
                    <Login />
                  </div>
                }
              />
              <Route
                path="/Forgot"
                element={
                  <div className="container">
                    <Forget />
                  </div>
                }
              />
              <Route
                path="/Test"
                element={
                  <div className="holdin">
                    <Additems />
                  </div>
                }
              />
              <Route
                path="/Additem"
                element={
                  <div className="holdin">
                    <Additems />
                  </div>
                }
              />
              <Route
                path="/ItemList"
                element={
                  <div className="holdin">
                    <Itemlist />
                  </div>
                }
              />
              <Route
                path="/AddCustomer"
                element={
                  <div className="holdin">
                    <AddCustomer />
                  </div>
                }
              />
              <Route
                path="/Customers"
                element={
                  <div className="holdin">
                    <Customers />
                  </div>
                }
              />
              <Route
                path="/Addvendor"
                element={
                  <div className="holdin">
                    <Addvendor />
                  </div>
                }
              />
              <Route
                path="/Vendors"
                element={
                  <div className="holdin">
                    <Vendor />
                  </div>
                }
              />
              <Route
                path="/ItemList"
                element={
                  <div className="holdin">
                    <Itemlist />
                  </div>
                }
              />
              <Route
                path="/Reciepts"
                element={
                  <div className="holdin">
                    <Reciepts />
                  </div>
                }
              />
              <Route
                path="/Viewreciept"
                element={
                  <div className="holdin">
                    <Recieptview />
                  </div>
                }
              />
              <Route
                path="/Invoices"
                element={
                  <div className="holdin">
                    <Invoices />
                  </div>
                }
              />
              <Route
                path="/Addsale"
                element={
                  <div className="holdin">
                    <Addsale />
                  </div>
                }
              />
            </Routes>
          </UserProvider>
        </Router>
      </div>
    </div>
  );
}

export default App;
