import React from "react";
import "./New.css";

export default function Recieptview() {
  return (
    <div className="viewtap">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Invoice details</h2>
            <h5>Okurut George</h5>
            <p>okurut17@gmail.com</p>
            <p>+256759206229</p>
          </div>
          <div className="col" align="right">
            <h2>Invoice number</h2>
            <p>#73564356384</p>
            <h5>Invoice date</h5>
            <p>#73564356384</p>
          </div>
        </div>
        <hr />
        <div>
          <ul>
            <li>No.</li>
            <li>item</li>
            <li>price</li>
            <li>qty</li>
            <li>total</li>
          </ul>
        </div>

        <div style={{ height: "50vh", background: "lightgrey" }}></div>
        <div>
          <div className="row">
            <div className="col">
              <h2>Pay method</h2>
              <p></p>
            </div>
            <div className="col">
              <div align="center">
                <div>Sub total : 1000</div>
                <div>tax : 1000</div>
                <hr />
                <h5>total : 1400 AED</h5>
              </div>
            </div>
          </div>
          <div align="center">
            <a href="https://mimzy.host/">https://mimzy.host/</a>
          </div>
        </div>
      </div>
    </div>
  );
}
