import axios from "axios";

let USER_API_URL = "http://localhost:4000/api/auth";

class Api {
  //----------------------------------------------------------------------------------------------------------------

  get(link) {
    return axios.get(`${USER_API_URL+link}`);
  }
  create(user) {
    return axios.post(`${USER_API_URL}/staffs/write.php`, user);
  }
  // getOne(Username, Password) {
  //   return axios.get(`${USER_API_URL}/single_user.php`, {
  //     params: { Username: Username, Password: Password },
  //   });
  // }
  delete(e) {
    return axios.delete(`${USER_API_URL}/staffs/delete.php`, {
      params: { id: e },
    });
  }
  update(data) {
    return axios.put(`${USER_API_URL}/staffs/update.php`, data);
  }

  // -------------------------------------------------------------------------------------------------------
}
export default new Api();
