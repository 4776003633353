import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../../context/context";
import Api from "../../Api/Api";
import Pagination from "../Pagination/Pagination";

let PageSize = 13;
export default function Itemlist() {
  const { items, getItems, getItemsData } = useContext(UserContext);
  const [select, setSelect] = useState({
    id: "",
    firstname: "   ",
    lastname: " ",
    gender: "",
    email: " ",
    phone: "",
    referee: " ",
    poster: " ",
  });

  const [currentPage, setCurrentPage] = useState(2);

  useEffect(() => {
    getItemsData();
    console.log("items", items);
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return items.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, items]);

  const selectArray = (a) => {
    // let data = customers[a];
    // setSelect(data);
  };

  const updatecustomersBtn = () => {
    Api.updatecustomer(select).then((res) => {
      console.log("Data", res.data);
    });
  };
  const deletecustomers = () => {
    Api.deletecustomer(select.id).then((res) => {
      console.log("delete", res);
    });
  };
  const addcustomers = () => {
    Api.createcustomers(select).then((res) => {
      console.log("Data", res.data);
    });
  };

  const customersList = items
    // .sort((a, b) => b.id - a.id)
    .map((a, _index) => (
      <div
        onClick={() => selectArray(_index)}
        key={_index}
        style={
          _index % 2 == 0
            ? {
                background: "lightblue",
                borderRadius: "8px",
              }
            : { background: "white", borderRadius: "8px" }
        }
      >
        <ul>
          <li>{a._id}</li>
          <li>{a.itemName}</li>
          <li>{a.itemTag}</li>
          <li>{a.itemCategory}</li>
          <li>
            <div className="manageui">
              <ion-icon name="create-outline"></ion-icon>
              <ion-icon name="eye-outline"></ion-icon>
              <ion-icon name="close-circle-outline"></ion-icon>
            </div>
          </li>
        </ul>
      </div>
    ));

  return (
    <div>
      <div className="dash">
        <div className="row">
          <div className="col" align="left">
            <h3>VIEW ITEMS</h3>
          </div>
          <div className="col" align="right">
            <input placeholder="Search Item" />
          </div>
        </div>
      </div>

      <div className="Itemheading">
        <ul style={{ fontWeight: "bold" }}>
          <li>ITEM CODE </li>
          <li>ITEM NAME</li>
          <li>DESCRIPTION</li>
          <li>CATEGORY</li>
          <li>ACTION</li>
        </ul>
        <div align="center">
          ----------------------------------------------------------------------------------------------------------------------
        </div>
      </div>

      <div className="Itemheading itemlistTab">{customersList}</div>

      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={items.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <hr />
    </div>
  );
}
