import React from "react";

export default function Reciepts() {
  return (
    <div>
      <div>
        <div className="dash">
          <div className="row">
            <div className="col" align="left">
              <h3>VIEW RECIEPTS</h3>
            </div>
            <div className="col" align="right">
              <input placeholder="Search Reciept" />
            </div>
          </div>
        </div>

        <div className="ItemInvoice">
          <ul style={{ fontWeight: "bold" }}>
            <li>ID </li>
            <li>REC. NO</li>
            <li>ITEM</li>
            <li>CUSTOMER NAME</li>
            <li>COST</li>
            <li>PAID</li>
            <li>BAL.</li>
            <li align="right">MANAGE</li>
          </ul>
          <hr />
        </div>
        <div className="itemlistTab ">
          {" "}
          <div className="ItemInvoice"></div>
        </div>

        <hr />
      </div>
    </div>
  );
}
