import React from "react";
import HeaderLogin from "./Header";
import "./Style.css";
import Swal from "sweetalert2";

export default function Login() {
  const handleKeyPress = (value, event) => {
    if (event.key === "Enter") {
      this.refs[event].focus();
    }
  };

  const login = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Login Failed!",
      footer: '<a href="">Database not yet connected?</a>',
    });
  };
  return (
    <div>
      <HeaderLogin />
      <div className="Login">
        <h4>MIMZY ACCOUNTS</h4>
        <hr />
        <br />
        <div className="rederror">
          <ion-icon name="information-outline"></ion-icon> Invalid User-ID or
          Password!
        </div>

        <br />
        <div className="row">
          <div className="col">
            <div>
              <p>
                Email Address <span style={{ color: "red" }}>*</span>
              </p>
              <input type="email" />
            </div>
            <div>
              <p>
                Password <span style={{ color: "red" }}>*</span>
              </p>
              <input type="password" />
            </div>
            <p>
              By using this site you agree to be bounded by the <br /> terms and
              conditions.
            </p>
          </div>
          <div className="next col-5">
            <br />
            <div>
              <input type="checkbox" style={{ marginRight: "10px" }} />
              Remember Email
              <hr />
              <button className="btn btn-primary" onClick={() => login()}>
                LOGIN
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}
