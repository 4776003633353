import React, { useState } from "react";
import "./item.css";
import axios from "axios";

export default function Additems() {
  const [itemInfo, setItemInfo] = useState({
    itemName: "",
    itemCategory: "",
    itemTag: "",
    sellingPrice: "",
    buyingPrice: "",
    timenow: "hsjsj",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItemInfo((prevItemInfo) => ({
      ...prevItemInfo,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      const response = await axios.post(
        "http://localhost:4000/api/auth/items",
        itemInfo
      );
      console.log("Registration successful!", response.data);
      alert("Success", "Registration successful!");
      // Handle successful registration, e.g., navigate to another screen.
    } catch (error) {
      console.error("Registration failed:", error);
      alert("Error", "Registration failed.");
    }
  };

  return (
    <div className="itemadd">
      <div className="dash">
        <h3>ADD ITEMS</h3>
      </div>

      <div className="addTabs">
        <div align="center">
          <label>Item Information</label>

          <div>
            Item name
            <input
              type="text"
              name="itemName"
              value={itemInfo.itemName}
              onChange={handleChange}
            />
          </div>
          <div>
            Item Category
            <input
              type="text"
              name="itemCategory"
              value={itemInfo.itemCategory}
              onChange={handleChange}
            />
          </div>
          <div>
            Item Tag
            <input
              type="text"
              name="itemTag"
              value={itemInfo.itemTag}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Sales Information</label>

            <div>
              Selling Price
              <input
                type="text"
                name="sellingPrice"
                value={itemInfo.sellingPrice}
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <label>Purchase Information</label>

            <div>
              Buying Price
              <input
                type="text"
                name="buyingPrice"
                value={itemInfo.buyingPrice}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <hr />
        <div align="right">
          <button type="button" className="btn btn-success">
            RESET
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            style={{ opacity: 0.3 }}
            onClick={() => handleSubmit()}
          >
            ADD ITEM
          </button>
        </div>
      </div>
    </div>
  );
}
