import { createContext, useEffect, useState, useMemo } from "react";
import Api from "../Components/Api/Api";
import axios from "axios";

// const APIURL = `https://rijarportal.onrender.com/api/auth/`;
const APIURL = `http://localhost:4000/api/auth`;
export const UserContext = createContext();
export const UserProvider = ({ children }) => {
  const [staff, setStaff] = useState([]);
  const [view, setView] = useState([]);
  const [customers, getCustomer] = useState([]);
  const [vendors, getVendor] = useState([]);
  const [items, getItems] = useState([]);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    getInvoice();
    getStaff();
    getCustomerData();
    getvendorsData();
    getItemsData();
    console.log("Contect call");
  }, []);

  const getInvoice = async () => {
    let arrdata = [];
    // await Api.getinvoice().then((res) => {
    //   const news = res.data;
    //   for (let a = 0; a < news.length; a++) {
    //     let id = news[a].id;
    //     let invoicenumber = news[a].invoicenumber;
    //     let customer = JSON.parse(news[a].customer.replace(/&quot;/g, '"'));
    //     let items = JSON.parse(news[a].items.replace(/&quot;/g, '"'));
    //     let paymentmode = JSON.parse(
    //       news[a].paymentmode.replace(/&quot;/g, '"')
    //     );
    //     let poster = news[a].poster;
    //     let cost = news[a].cost;
    //     let paid = news[a].paid;
    //     let duedate = news[a].duedate;
    //     let savetime = news[a].savetime;
    //     let saleperson = news[a].saleperson;
    //     let obj = {
    //       id: id,
    //       customer: customer,
    //       saleperson: saleperson,
    //       items: items,
    //       paymentmode: paymentmode,
    //       duedate: duedate,
    //       poster: poster,
    //       cost: cost,
    //       paid: paid,
    //       invoicenumber: invoicenumber,
    //       savetime: savetime,
    //     };
    //     arrdata.push(obj);
    //     // console.log("inv", obj);
    //   }
    // });
    setInvoices(arrdata);
  };
  const getStaff = async () => {
    let Arr = [];
    // try {
    //   const response = await axios.get(`${APIURL}/Staff`);
    //   // const { address, balance, sbal, timenow } = response.data;
    // } catch (error) {
    //   console.error("Error fetching user address:", error);
    // }
    setStaff(Arr);
    setView(Arr);
  };
  const getCustomerData = async () => {
    // let Arr = [];
    try {
      const response = await axios.get(`${APIURL}/Customers`);
      console.log("Response:", response.data);
      // Arr = response.data;
      // getItems(response.data);
      getCustomer(response.data);
      console.log("Customer:", response.data);
      // getCustomer(Arr);
    } catch (error) {
      console.error("Error fetching Items address:", error);
    }
  };
  const getvendorsData = async () => {
    // let Arr = [];
    try {
      const response = await axios.get(`${APIURL}/Vendors`);
      console.log("Response:", response.data);
      // Arr = response.data;
      // getItems(response.data);
      getVendor(response.data);
    } catch (error) {
      console.error("Error fetching Items address:", error);
    }
  };
  const getItemsData = async () => {
    console.log("Getting data here testing");
    try {
      const response = await axios.get(`${APIURL}/items`);
      console.log("Response:", response.data);
      // Arr = response.data;
      getItems(response.data);
    } catch (error) {
      console.error("Error fetching Items address:", error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        staff,
        view,
        setView,
        setStaff,
        getStaff,
        customers,
        getCustomer,
        getCustomerData,
        items,
        getItems,
        getCustomerData,
        getItemsData,
        getInvoice,
        invoices,
        vendors,
        getVendor,
        getvendorsData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
