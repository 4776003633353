import { AutoComplete } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../../context/context";
import Api from "../../Api/Api";
import "./style.css";

export default function Addsale() {
  const { customers, setCustomer, getCustomerData, items } =
    useContext(UserContext);
  const [selectedBank, setSelectBank] = useState(0);
  const [searchItem, setsearchItem] = useState("");
  const [searchItemBox, setsearchItemBox] = useState("");
  const [custselect, setCustSelect] = useState(false);
  const [custItem, setItemSelect] = useState(false);
  const [selected, setSelect] = useState(10);
  const [selectedCash, setSelectCash] = useState(0);
  const [selectedMobile, setSelectMobile] = useState(0);
  const [staffArray, setStaffArray] = useState([]);
  const [activeDiv, setactiveDiv] = useState(0);
  const setActivePay = (a) => {
    setSelect(a);
    if (a == 0) {
      setSelectCash(1);
    }
    if (a == 1) {
      setSelectBank(1);
    }
    if (a == 2) {
      setSelectMobile(1);
    }
  };
  const bank = [
    {
      name: "Cash",
      icon: <ion-icon name="wallet-outline"></ion-icon>,
    },
    {
      name: "Bank",
      icon: <ion-icon name="business-outline"></ion-icon>,
    },
    {
      name: "Wallet",
      icon: <ion-icon name="wallet-outline"></ion-icon>,
    },
  ];
  useEffect(() => {
    getCustomerData();
  }, [0]);
  const [cust, setcus] = useState([
    {
      name: "",
      phone: "",
      email: "",
    },
  ]);
  const [bankData, setBank] = useState({
    cash: selectedCash,
    adcb: 0,
    nbd: selectedBank,
    eq: 0,
    cen: 0,
    mtn: 0,
    airtel: selectedMobile,
    ex: 0,
  });
  const [itemData, setitemdata] = useState([
    {
      items: "",
      passenger: "",
      passportno: "",
      nationality: "",
      itemcost: "",
    },
  ]);

  const onFormInputChange = (event) => {
    const { name, value } = event.target;
    setInvoice({ ...invoicedata, [name]: value });
  };
  const onFormInputCustomer = (a, b, c) => {
    let data = [...cust];
    data[0]["name"] = a;
    data[0]["phone"] = b;
    data[0]["email"] = c;
    setcus(data);
    console.log("cust", cust);
  };

  const addFields = (e) => {
    let object = {
      items: "",
      passenger: "",
      passportno: "",
      nationality: "Uganda",
      itemcost: "",
    };
    setitemdata([...itemData, object]);
  };
  const handleFormChangeItem = (event, index) => {
    const { name, value } = event.target;
    let data = [...itemData];
    data[index][name] = value;
    setitemdata(data);
  };
  const handleFormItem = (datav, index, price) => {
    // const { name, value } = event.target;
    let data = [...itemData];
    data[index]["items"] = datav;
    data[index]["itemcost"] = price;
    setitemdata(data);
  };
  const searchCustomer = useMemo(() => {
    if (customers.length > 0) {
      let computedTodos = customers;
      if (searchItem) {
        let arr = [];
        if (arr.length < 1) {
          arr = computedTodos.filter((todo) =>
            todo.firstname.toLowerCase().includes(searchItem.toLowerCase())
          );
        }
        if (arr.length < 1) {
          arr = computedTodos.filter((todo) =>
            todo.lastname.toLowerCase().includes(searchItem.toLowerCase())
          );
        }
        computedTodos = arr;
      } else {
        computedTodos = [];
      }
      return computedTodos;
    } else {
      return [];
    }
  }, [searchItem, customers]);
  const searchitemside = useMemo(() => {
    if (items.length > 0) {
      let computedTodos = items;
      if (searchItemBox) {
        let arr = [];
        if (arr.length < 1) {
          arr = computedTodos.filter((todo) =>
            todo.itemName.toLowerCase().includes(searchItemBox.toLowerCase())
          );
        }
        if (arr.length < 1) {
          arr = computedTodos.filter((todo) =>
            todo.itemCategory
              .toLowerCase()
              .includes(searchItemBox.toLowerCase())
          );
        }
        computedTodos = arr;
      } else {
        computedTodos = [];
      }
      return computedTodos;
    } else {
      return [];
    }
  }, [searchItemBox, items]);
  const customerView = searchCustomer.map((a, index) => (
    <ul
      onClick={() => {
        setsearchItem(a.firstname + " " + a.lastname);
        setCustSelect(true);
        onFormInputCustomer(a.firstname + " " + a.lastname, a.mobile, a.email);
      }}
    >
      <li align="left">{a.firstname + " " + a.lastname}</li>
      <li align="right">{a.mobile}</li>
    </ul>
  ));
  const ItemView = searchitemside.map((a, index) => (
    <div
      key={index}
      onClick={() => {
        setsearchItemBox(a.itemName + " " + "|" + " " + a.itemCategory);
        setItemSelect(true);
        //  handleFormChangeItem(e, b);
      }}
    >
      {a.itemName + " " + "|" + " " + a.itemCategory}
    </div>
  ));
  const itemdiv = itemData.map((a, b) => (
    <ul>
      <li>
        <input value={b + 1} disabled />
      </li>
      <li>
        <div className="sales salesItems">
          <input
            // value={searchItemBox}
            value={a.items}
            name="items"
            onChange={(e) => {
              setsearchItemBox(e.target.value);
              setItemSelect(false);
              handleFormChangeItem(e, b);
            }}
            onClick={() => setactiveDiv(b)}
          />
          {custItem || searchItemBox.length == "" ? (
            <div></div>
          ) : (
            <div
              className="searchbox"
              style={
                activeDiv == b ? { display: "block" } : { display: "none" }
              }
            >
              <div className="custview" style={{ height: "auto" }}>
                {searchitemside.length > 0 ? (
                  searchitemside.map((a, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setsearchItemBox(
                          a.itemName + " " + "|" + " " + a.itemCategory
                        );
                        setItemSelect(true);
                        handleFormItem(
                          a.itemName + " " + "|" + " " + a.itemCategory,
                          b,
                          a.sellingPrice
                        );
                      }}
                    >
                      {a.itemName + " " + "|" + " " + a.itemCategory}
                    </div>
                  ))
                ) : (
                  <button className="btn btn-primary">ADD ITEM</button>
                )}
              </div>
            </div>
          )}
        </div>
      </li>
      <li>
        <input
          value={a.passenger}
          onChange={(e) => {
            onFormInputChange(e);
            handleFormChangeItem(e, b);
          }}
          name="passenger"
          style={{ textTransform: "capitalize" }}
        />
      </li>
      <li>
        <input
          value={a.passportno}
          onChange={(e) => {
            onFormInputChange(e);
            handleFormChangeItem(e, b);
          }}
          style={{
            width: "100%",
            textTransform: "capitalize",
            textAlign: "center",
          }}
          name="passportno"
        />
      </li>
      <li>
        <select
          className="selectarray"
          value={a.nationality}
          onChange={(e) => {
            onFormInputChange(e);
            handleFormChangeItem(e, b);
          }}
          name="nationality"
        >
          <option value="">Please select Nationality</option>
          <option value="Afghanistan">Select Country</option>
          <option value="Afghanistan">Afghanistan</option>
          <option value="Åland Islands">Åland Islands</option>
          <option value="Albania">Albania</option>
          <option value="Algeria">Algeria</option>
          <option value="American Samoa">American Samoa</option>
          <option value="Andorra">Andorra</option>
          <option value="Angola">Angola</option>
          <option value="Anguilla">Anguilla</option>
          <option value="Antarctica">Antarctica</option>
          <option value="Antigua and Barbuda">Antigua and Barbuda</option>
          <option value="Argentina">Argentina</option>
          <option value="Armenia">Armenia</option>
          <option value="Aruba">Aruba</option>
          <option value="Australia">Australia</option>
          <option value="Austria">Austria</option>
          <option value="Azerbaijan">Azerbaijan</option>
          <option value="Bahamas">Bahamas</option>
          <option value="Bahrain">Bahrain</option>
          <option value="Bangladesh">Bangladesh</option>
          <option value="Barbados">Barbados</option>
          <option value="Belarus">Belarus</option>
          <option value="Belgium">Belgium</option>
          <option value="Belize">Belize</option>
          <option value="Benin">Benin</option>
          <option value="Bermuda">Bermuda</option>
          <option value="Bhutan">Bhutan</option>
          <option value="Bolivia">Bolivia</option>
          <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
          <option value="Botswana">Botswana</option>
          <option value="Bouvet Island">Bouvet Island</option>
          <option value="Brazil">Brazil</option>
          <option value="British Indian Ocean Territory">
            British Indian Ocean Territory
          </option>
          <option value="Brunei Darussalam">Brunei Darussalam</option>
          <option value="Bulgaria">Bulgaria</option>
          <option value="Burkina Faso">Burkina Faso</option>
          <option value="Burundi">Burundi</option>
          <option value="Cambodia">Cambodia</option>
          <option value="Cameroon">Cameroon</option>
          <option value="Canada">Canada</option>
          <option value="Cape Verde">Cape Verde</option>
          <option value="Cayman Islands">Cayman Islands</option>
          <option value="Central African Republic">
            Central African Republic
          </option>
          <option value="Chad">Chad</option>
          <option value="Chile">Chile</option>
          <option value="China">China</option>
          <option value="Christmas Island">Christmas Island</option>
          <option value="Cocos (Keeling) Islands">
            Cocos (Keeling) Islands
          </option>
          <option value="Colombia">Colombia</option>
          <option value="Comoros">Comoros</option>
          <option value="Congo">Congo</option>
          <option value="Congo, The Democratic Republic of The">
            Congo, The Democratic Republic of The
          </option>
          <option value="Cook Islands">Cook Islands</option>
          <option value="Costa Rica">Costa Rica</option>
          <option value="Cote D'ivoire">Cote D'ivoire</option>
          <option value="Croatia">Croatia</option>
          <option value="Cuba">Cuba</option>
          <option value="Cyprus">Cyprus</option>
          <option value="Czech Republic">Czech Republic</option>
          <option value="Denmark">Denmark</option>
          <option value="Djibouti">Djibouti</option>
          <option value="Dominica">Dominica</option>
          <option value="Dominican Republic">Dominican Republic</option>
          <option value="Ecuador">Ecuador</option>
          <option value="Egypt">Egypt</option>
          <option value="El Salvador">El Salvador</option>
          <option value="Equatorial Guinea">Equatorial Guinea</option>
          <option value="Eritrea">Eritrea</option>
          <option value="Estonia">Estonia</option>
          <option value="Ethiopia">Ethiopia</option>
          <option value="Falkland Islands (Malvinas)">
            Falkland Islands (Malvinas)
          </option>
          <option value="Faroe Islands">Faroe Islands</option>
          <option value="Fiji">Fiji</option>
          <option value="Finland">Finland</option>
          <option value="France">France</option>
          <option value="French Guiana">French Guiana</option>
          <option value="French Polynesia">French Polynesia</option>
          <option value="French Southern Territories">
            French Southern Territories
          </option>
          <option value="Gabon">Gabon</option>
          <option value="Gambia">Gambia</option>
          <option value="Georgia">Georgia</option>
          <option value="Germany">Germany</option>
          <option value="Ghana">Ghana</option>
          <option value="Gibraltar">Gibraltar</option>
          <option value="Greece">Greece</option>
          <option value="Greenland">Greenland</option>
          <option value="Grenada">Grenada</option>
          <option value="Guadeloupe">Guadeloupe</option>
          <option value="Guam">Guam</option>
          <option value="Guatemala">Guatemala</option>
          <option value="Guernsey">Guernsey</option>
          <option value="Guinea">Guinea</option>
          <option value="Guinea-bissau">Guinea-bissau</option>
          <option value="Guyana">Guyana</option>
          <option value="Haiti">Haiti</option>
          <option value="Heard Island and Mcdonald Islands">
            Heard Island and Mcdonald Islands
          </option>
          <option value="Holy See (Vatican City State)">
            Holy See (Vatican City State)
          </option>
          <option value="Honduras">Honduras</option>
          <option value="Hong Kong">Hong Kong</option>
          <option value="Hungary">Hungary</option>
          <option value="Iceland">Iceland</option>
          <option value="India">India</option>
          <option value="Indonesia">Indonesia</option>
          <option value="Iran, Islamic Republic of">
            Iran, Islamic Republic of
          </option>
          <option value="Iraq">Iraq</option>
          <option value="Ireland">Ireland</option>
          <option value="Isle of Man">Isle of Man</option>
          <option value="Israel">Israel</option>
          <option value="Italy">Italy</option>
          <option value="Jamaica">Jamaica</option>
          <option value="Japan">Japan</option>
          <option value="Jersey">Jersey</option>
          <option value="Jordan">Jordan</option>
          <option value="Kazakhstan">Kazakhstan</option>
          <option value="Kenya">Kenya</option>
          <option value="Kiribati">Kiribati</option>
          <option value="Korea, Democratic People's Republic of">
            Korea, Democratic People's Republic of
          </option>
          <option value="Korea, Republic of">Korea, Republic of</option>
          <option value="Kuwait">Kuwait</option>
          <option value="Kyrgyzstan">Kyrgyzstan</option>
          <option value="Lao People's Democratic Republic">
            Lao People's Democratic Republic
          </option>
          <option value="Latvia">Latvia</option>
          <option value="Lebanon">Lebanon</option>
          <option value="Lesotho">Lesotho</option>
          <option value="Liberia">Liberia</option>
          <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
          <option value="Liechtenstein">Liechtenstein</option>
          <option value="Lithuania">Lithuania</option>
          <option value="Luxembourg">Luxembourg</option>
          <option value="Macao">Macao</option>
          <option value="Macedonia, The Former Yugoslav Republic of">
            Macedonia, The Former Yugoslav Republic of
          </option>
          <option value="Madagascar">Madagascar</option>
          <option value="Malawi">Malawi</option>
          <option value="Malaysia">Malaysia</option>
          <option value="Maldives">Maldives</option>
          <option value="Mali">Mali</option>
          <option value="Malta">Malta</option>
          <option value="Marshall Islands">Marshall Islands</option>
          <option value="Martinique">Martinique</option>
          <option value="Mauritania">Mauritania</option>
          <option value="Mauritius">Mauritius</option>
          <option value="Mayotte">Mayotte</option>
          <option value="Mexico">Mexico</option>
          <option value="Micronesia, Federated States of">
            Micronesia, Federated States of
          </option>
          <option value="Moldova, Republic of">Moldova, Republic of</option>
          <option value="Monaco">Monaco</option>
          <option value="Mongolia">Mongolia</option>
          <option value="Montenegro">Montenegro</option>
          <option value="Montserrat">Montserrat</option>
          <option value="Morocco">Morocco</option>
          <option value="Mozambique">Mozambique</option>
          <option value="Myanmar">Myanmar</option>
          <option value="Namibia">Namibia</option>
          <option value="Nauru">Nauru</option>
          <option value="Nepal">Nepal</option>
          <option value="Netherlands">Netherlands</option>
          <option value="Netherlands Antilles">Netherlands Antilles</option>
          <option value="New Caledonia">New Caledonia</option>
          <option value="New Zealand">New Zealand</option>
          <option value="Nicaragua">Nicaragua</option>
          <option value="Niger">Niger</option>
          <option value="Nigeria">Nigeria</option>
          <option value="Niue">Niue</option>
          <option value="Norfolk Island">Norfolk Island</option>
          <option value="Northern Mariana Islands">
            Northern Mariana Islands
          </option>
          <option value="Norway">Norway</option>
          <option value="Oman">Oman</option>
          <option value="Pakistan">Pakistan</option>
          <option value="Palau">Palau</option>
          <option value="Palestinian Territory, Occupied">
            Palestinian Territory, Occupied
          </option>
          <option value="Panama">Panama</option>
          <option value="Papua New Guinea">Papua New Guinea</option>
          <option value="Paraguay">Paraguay</option>
          <option value="Peru">Peru</option>
          <option value="Philippines">Philippines</option>
          <option value="Pitcairn">Pitcairn</option>
          <option value="Poland">Poland</option>
          <option value="Portugal">Portugal</option>
          <option value="Puerto Rico">Puerto Rico</option>
          <option value="Qatar">Qatar</option>
          <option value="Reunion">Reunion</option>
          <option value="Romania">Romania</option>
          <option value="Russian Federation">Russian Federation</option>
          <option value="Rwanda">Rwanda</option>
          <option value="Saint Helena">Saint Helena</option>
          <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
          <option value="Saint Lucia">Saint Lucia</option>
          <option value="Saint Pierre and Miquelon">
            Saint Pierre and Miquelon
          </option>
          <option value="Saint Vincent and The Grenadines">
            Saint Vincent and The Grenadines
          </option>
          <option value="Samoa">Samoa</option>
          <option value="San Marino">San Marino</option>
          <option value="Sao Tome and Principe">Sao Tome and Principe</option>
          <option value="Saudi Arabia">Saudi Arabia</option>
          <option value="Senegal">Senegal</option>
          <option value="Serbia">Serbia</option>
          <option value="Seychelles">Seychelles</option>
          <option value="Sierra Leone">Sierra Leone</option>
          <option value="Singapore">Singapore</option>
          <option value="Slovakia">Slovakia</option>
          <option value="Slovenia">Slovenia</option>
          <option value="Solomon Islands">Solomon Islands</option>
          <option value="Somalia">Somalia</option>
          <option value="South Africa">South Africa</option>
          <option value="South Georgia and The South Sandwich Islands">
            South Georgia and The South Sandwich Islands
          </option>
          <option value="Spain">Spain</option>
          <option value="Sri Lanka">Sri Lanka</option>
          <option value="Sudan">Sudan</option>
          <option value="Suriname">Suriname</option>
          <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
          <option value="Swaziland">Swaziland</option>
          <option value="Sweden">Sweden</option>
          <option value="Switzerland">Switzerland</option>
          <option value="Syrian Arab Republic">Syrian Arab Republic</option>
          <option value="Taiwan">Taiwan</option>
          <option value="Tajikistan">Tajikistan</option>
          <option value="Tanzania, United Republic of">
            Tanzania, United Republic of
          </option>
          <option value="Thailand">Thailand</option>
          <option value="Timor-leste">Timor-leste</option>
          <option value="Togo">Togo</option>
          <option value="Tokelau">Tokelau</option>
          <option value="Tonga">Tonga</option>
          <option value="Trinidad and Tobago">Trinidad and Tobago</option>
          <option value="Tunisia">Tunisia</option>
          <option value="Turkey">Turkey</option>
          <option value="Turkmenistan">Turkmenistan</option>
          <option value="Turks and Caicos Islands">
            Turks and Caicos Islands
          </option>
          <option value="Tuvalu">Tuvalu</option>
          <option value="Uganda" selected>
            Uganda
          </option>
          <option value="Ukraine">Ukraine</option>
          <option value="United Arab Emirates">United Arab Emirates</option>
          <option value="United Kingdom">United Kingdom</option>
          <option value="United States">United States</option>
          <option value="United States Minor Outlying Islands">
            United States Minor Outlying Islands
          </option>
          <option value="Uruguay">Uruguay</option>
          <option value="Uzbekistan">Uzbekistan</option>
          <option value="Vanuatu">Vanuatu</option>
          <option value="Venezuela">Venezuela</option>
          <option value="Viet Nam">Viet Nam</option>
          <option value="Virgin Islands, British">
            Virgin Islands, British
          </option>
          <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
          <option value="Wallis and Futuna">Wallis and Futuna</option>
          <option value="Western Sahara">Western Sahara</option>
          <option value="Yemen">Yemen</option>
          <option value="Zambia">Zambia</option>
          <option value="Zimbabwe">Zimbabwe</option>
        </select>
      </li>
      <li>
        <input disabled />
      </li>
      <li>
        <input
          type="number"
          value={a.itemcost}
          onChange={(e) => {
            onFormInputChange(e);
            handleFormChangeItem(e, b);
          }}
          name="itemcost"
          align="right"
        />
      </li>
    </ul>
  ));
  const PayeTabs = bank.map((i, index) => (
    <li
      onClick={() => setActivePay(index)}
      style={
        index == selected
          ? { background: "brown", color: "white" }
          : { background: " " }
      }
    >
      {i.icon}
      <br />
      {i.name}
    </li>
  ));
  const InvoiceCost = () => {
    let data = 0;
    for (let a = 0; a < itemData.length; a++) {
      let v = Number(itemData[a].itemcost);
      data = Number(data + v);
    }
    return data;
  };
  const BalanceCost = () => {
    // let a = Number(InvoiceCost());
    let b =
      Number(selectedCash) + Number(selectedMobile) + Number(selectedBank);
    return Number(b);
  };

  const [invoicedata, setInvoice] = useState({
    savetime: "",
    duedate: "",
    invoicenumber:
      Math.floor(Math.random() * (500000000 - 100000000 + 1)) + 100000000,
    customer: cust,
    saleperson: " ",
    items: itemData,
    cost: parseInt(InvoiceCost()),
    paid: Number(selectedCash) + Number(selectedMobile) + Number(selectedBank),
    poster: "",
    paymentmode: [bankData],
  });

  const addinvoice = async () => {
    console.log("csotdata", BalanceCost());
    console.log("invoicedata", invoicedata);
    // try {
    //   const response = await axios.post(
    //     "http://localhost:4000/api/auth/invoice",
    //     invoicedata
    //   );
    //   getCustomerData();
    //   console.log("Registration successful!", response.data);
    //   alert("Success", "Registration successful!");
    //   // Handle successful registration, e.g., navigate to another screen.
    // } catch (error) {
    //   console.error("Registration failed:", error);
    //   alert("Error", "Registration failed.");
    // }
  };
  return (
    <div>
      <div className="dash" style={{ background: "#169597", padding: 0 }}>
        <br />
        <h3>ADD INVOICES</h3>
        <hr />
        <div
          className="addinvocie"
          style={{ background: "white", color: "#0077b6" }}
        >
          <br />
          <div className="row">
            <div className="col">
              <div>Date :</div>
              <input
                type="datetime-local"
                onChange={(e) => onFormInputChange(e)}
                name="savetime"
                value={invoicedata.savetime}
              />
            </div>
            <div className="col"></div>
            <div className="col">
              <div>Customer name :</div>
              <div className="sales">
                <input
                  className=""
                  type=""
                  style={{
                    width: "100%",
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                  value={searchItem}
                  onChange={(e) => {
                    setsearchItem(e.target.value);
                    setCustSelect(false);
                  }}
                  name="customer"
                />
                {custselect == true || searchItem.length == "" ? (
                  <div></div>
                ) : (
                  <div className="searchbox">
                    <ul>
                      <li>Name</li>
                      <li>Contact No.</li>
                    </ul>
                    <div className="custview">
                      {searchCustomer.length > 0 ? (
                        customerView
                      ) : (
                        <button className="btn btn-primary">
                          ADD NEW CUSTOMER
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col">
              <div>Sale person :</div>

              <select
                type="text"
                onChange={(e) => onFormInputChange(e)}
                name="saleperson"
                style={{
                  textTransform: "capitalize",
                  textAlign: "center",
                  width: "60%",
                  padding: "10px",
                }}
              >
                <option>Irene</option>
                <option>Rose</option>
                <option>Paul</option>
                <option>Halima</option>
              </select>
            </div>
          </div>
          <hr />
          <div align="left" className="itemsinput">
            <ul>
              <li>Id</li>
              <li>Item</li>
              <li>Name</li>
              <li>Passport</li>
              <li>Nationality</li>
              <li>Vat</li>
              <li>Cost</li>
            </ul>
            <hr />
            <div className="itemreciepts">{itemdiv}</div>
            <div>
              <br />
              <div className="dataobj">
                <div className="row">
                  <div className="col-9" align="center">
                    <div align="center">
                      <h6>Payment Mode</h6>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label>CASH</label>
                        <br />
                        <input
                          style={{ width: "90%", textAlign: "center" }}
                          onChange={(e) => setSelectCash(e.target.value)}
                          value={selectedCash}
                        />
                      </div>
                      <div className="col">
                        <label>BANK</label>
                        <br />
                        <input
                          style={{ width: "90%", textAlign: "center" }}
                          value={selectedBank}
                          onChange={(e) => setSelectBank(e.target.value)}
                        />

                        {/* <div className="option">
                          ....................................
                          <br />
                          Select Bank <br />
                          ....................................
                          <br />
                          <span>EQUITY</span>
                          <span>CENTENARY</span>
                          <span>ADCB</span>
                          <span>NBD</span>
                        </div> */}
                        <br />
                      </div>
                      <div className="col">
                        <label>WALLET</label>
                        <br />
                        <input
                          style={{ width: "90%", textAlign: "center" }}
                          onChange={(e) => setSelectMobile(e.target.value)}
                          value={selectedMobile}
                          // style={{ textAlign: "center" }}
                        />
                        <div className="option">
                          ....................................
                          <br />
                          Select Wallet <br />
                          ....................................
                          <br />
                          <span>AIRTEL</span>
                          <span>MTN</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col pay" style={{ border: "1px solid" }}>
                    <div>
                      <div className="row">
                        <div className="col">
                          <h4>Total Cost : </h4>
                        </div>
                        <div className="col">{InvoiceCost()} AED</div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <h4>Balance </h4>
                        </div>
                        <div className="col">
                          {InvoiceCost() - BalanceCost()} AED
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <h4>Total Paid : </h4>
                        </div>
                        <div className="col">{BalanceCost()} AED</div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <button
                      onClick={() => addinvoice()}
                      style={{
                        margin: 10,
                        padding: 5,
                        border: "none",
                        width: "60%",
                      }}
                    >
                      add invoice
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
