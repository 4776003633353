import React from "react";
import "./Style.css";

export default function HeaderLogin() {
  return (
    <div className="LoginHeader">
      <div className="row">
        <div className="col"> </div>
        <div className="col" align="right">
          <ion-icon name="log-out-outline"></ion-icon>
          Sign In
          <label>|</label>
          <ion-icon name="finger-print-outline"></ion-icon>
          Forgot Password
        </div>
      </div>
    </div>
  );
}
