import React from "react";
import HeaderLogin from "./Header";
import "./Style.css";

export default function Login() {
  const handleKeyPress = (value, event) => {
    if (event.key === "Enter") {
      this.refs[event].focus();
    }
  };
  return (
    <div>
      <HeaderLogin />
      <div className="Login">
        <h4></h4>
        <hr />
        <br />
        <div className="row">
          <div className="col">
            <div>
              <p>
                Email Address <span style={{ color: "red" }}>*</span>
              </p>
              <input type="email" />
            </div>
            <div>
              <p>
                Password <span style={{ color: "red" }}>*</span>
              </p>
              <input type="password" />
            </div>
            <p>
              By using this site you agree to be bounded by the <br /> terms and
              conditions.
            </p>
          </div>
          <div className="next col-4">
            <div>
              <p>
                Captcha <span style={{ color: "red" }}>*</span>
              </p>
              <input className="Captcha" type="email" />
            </div>
            <br />
            <div>
              <input type="checkbox" style={{ marginRight: "10px" }} />
              Remember Email
              <hr />
              <button className="btn btn-primary">LOGIN</button>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}
